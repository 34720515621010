const questions = [
  {
    text: 'ثبت نام در این رویداد چگونه هستش؟',
    answer: 'تمامی مراحل ثبت نام در رویداد در همین سایت انجام میشه.'
  },
  {
    text: 'از کجا بدونیم که این رویداد به دردمون میخوره؟',
    answer: 'برای اینکه از این بابت مطمئن بشید میتونید قسمت نظرات شرکت کنندگان داناکی گذشته رو مشاهده کنین یا در صفحات داناک و رستا در شبکه های اجتماعی نظرات شرکت کنندگان گذشته رو بخونید.'
  },
  {
    text: 'چه کسانی میتونند در این رویداد ثبت نام کنند؟',
    answer: ' تمامی دانش آموزان پایه هشتم، نهم و دهم میتونند بدون محدودیتی در این رویداد ثبت نام کنند.'
  },

  {
    text: 'هزینه ثبت نام چقدره؟',
    answer: 'هزینه ثبت نام انفرادی : ۷۰.۰۰۰ تومن برای هرنفر.\n' +
        'و به صورت گروهی : ۵۰.۰۰۰ تومن برای هرنفر.'
  },

  // {
  //   text: 'کجا باید ثبت‌نام کنیم؟',
  //   answer: 'بابا اینارو همین دو تا سوال قبل‌تر گفتم! ثبت‌نام توی همین سایت(interkarsolar.ir) انجام میشه.'
  // },

  {
    text: 'مدت زمان رویداد و مسابقاتش چقدر میشه در کل؟',
    answer: 'مدت زمان اجرای رویداد در حدود 3 روز هستش.'
  },

  {
    text: 'اخبار رویدادو از کجا دنبال کنیم؟',
    answer: 'اخبار مربوط به رویداد رو میتونید از کانال تلگرامی رستا و داناک دنبال کنید.'

  },

  // {
  //   text: 'برای مرحله اول چقدر زمان دارم؟',
  //   answer: 'تا ساعت ۱۲ شب جمعه ۶ اسفند هر زمان که خواستید می‌تونید آزمون را شروع کنید و بعد از آن هر چندبار که خواستید وارد بشید و پاسخ‌هاتون را تغییر بدید پس اصلا نگران زمان نباشید.'
  // },

  {
    text: 'رویداد تک نفرس یا گروهی؟',
    answer: 'رویداد گروهی بوده و به شکل تیم های سه نفره انجام میپذیره. '
  },

  {
    text: 'منی که گروه ندارم چه کار کنم؟',
    answer: 'مشکلی از این بابت نداشته باشید چون ما کسایی رو که فردی ثبت نام کردن رو چند گروه جداگانه براشون میسازیم.'
  },
  // {
  //   text: 'اگه بخوایم خودمونو برای مرحله اول آماده کنین، چی کار باید کنیم؟',
  //   answer: 'هیچی! سوالات اینترکارسولار کاملا متکی به خلاقیت و منطق شما هستند و هیچ پیش نیازی ندارند! ولی اگه دوست داشتید تا بیشتر با نمونه سوال های کارسوق آشنا بشید، می تونید به بخش سوالات سال های گذشته سر بزنید! (دکمه‌اش اون بالاست)'
  // },

];

export default questions;
